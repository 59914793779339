<template>
  <div class="remindResponse" ref="remindResponse">
    <van-pull-refresh
        v-model="loading"
        class="workWait"
        id="workWait"
        @refresh="searchEvent"
    >
      <div class="list" v-if="list.length > 0">
        <overflowYHidden>
          <subitemV4
              @clickItem="toRepairedOrder(item)"
              :rowKeyList="rowKeyList"
              v-for="item in list"
              :key="item.key"
              :dataInfo="item"
          >
            <template slot="footer">
              <div class="headleBtn" @click.stop="toReminderInfo(item)">
                催单信息
              </div>
              <div v-if="showSend(item)" class="headleBtn" @click.stop="toAppointRole(item)">
                指派工程师
              </div>
            </template>
          </subitemV4>
          <div class="loadMore" v-show="loadMore">
            <i class="el-icon-loading"></i>更多加载中...
          </div>
          <div
              class="loadMore"
              v-show="totalCount != 0 && totalCount == list.length"
          >
            没有更多数据了
          </div>
        </overflowYHidden>
      </div>
      <div v-else class="emptyView">
        <KcEmpty description="暂无数据"></KcEmpty>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import subitemV4 from './subitemV4'
import overflowYHidden from '@/components/overflowYHidden'
import repairMixin from '@views/shareAndDispatch/device/deviceRepair/reapirList/repairMixin'

export default {
  name: 'remindResponse',
  components: { subitemV4, overflowYHidden },
  mixins: [repairMixin],
  data() {
    return {
      page: 1,
      pageSize: 30,
      totalCount: 0,
      loadMore: false,
      loading: false,
      rowKeyList: [
        { key: 'deviceCode', name: '设备编号', space: '21px', float: 'right' },
        {
          key: 'categoryName',
          name: '设备类型',
          space: '21px',
          float: 'right'
        },
        { key: 'reportUser', name: '报修人', space: '21px', float: 'right' },
        { key: 'reportTime', name: '报障时间', space: '21px', float: 'right' }
      ],
      list: [],
      scrollbarEl: ''
    }
  },
  created() {
  },
  mounted() {
    this.reportList()
    this.scrollbarEl = this.$refs.remindResponse
    this.scrollbarEl.onscroll = () => {
      this.scrollEvent()
    }
  },
  methods: {
    showSend: function(item) {
      if (this.$valueIsExist(item, 'status') == false) {
        return true
      }
      let status = item['status']
      if (
          status == 5
          || status == 6
          || status == 7
          || status == 8
          || status == 11 || status == 15 || status == 16) {
        return false
      }
      return true
    },
    scrollEvent() {
      let scrollTop = this.scrollbarEl.scrollTop
      let offsetHeight = this.scrollbarEl.offsetHeight
      let scrollHeight = this.scrollbarEl.scrollHeight
      if (
          scrollTop + offsetHeight == scrollHeight &&
          this.totalCount > this.list.length
      ) {
        this.page += 1
        this.loadMore = true
        this.reportList()
      }
    },
    searchEvent() {
      this.page = 1
      this.loadMore = true
      this.reportList()
    },
    reportList() {
      this.$showLoading({
        target: '.remindResponse'
      })
      this.$api.deviceRepair
          .reportList({
            queryType: 2,
            page: this.page,
            pageSize: this.pageSize
          })
          .then((res) => {
            this.loadMore = false
            this.loading = false
            this.totalCount = res.totalCount
            if (this.page == 1) {
              this.list = res.data
            } else {
              this.list.push(...res.data)
            }
            this.$hideLoading({
              target: '.remindResponse'
            })
          })
          .catch((res) => {
            this.loading = false
            this.$hideLoading({
              target: '.remindResponse'
            })
          })
    },
    toAppointRole(item) {
      this.$push('deviceRepair/appointRole', {
        id: item.id
      })
    },
    toReminderInfo(item) {
      this.$push('deviceRepair/reminderInfo', {
        id: item.id
      })
    },
    toRepairedOrder(item) {
      this.$push('deviceRepair/repairedOrder', {
        id: item.id,
        serialNumber: item.serialNumber,
        categoryName: item.categoryName,
        reportTime: item.reportTime
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@styles/variables.scss";

.remindResponse {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 20px 10px;

  .list {
    height: 100%;
  }

  .emptyView {
    height: 100%;
  }

  .loadMore {
    text-align: center;
    color: $color4;
    font-size: 12px;
    padding: 12px;
  }
}
</style>